import React, { Component } from 'react';
import './Pelotita.css';

class Pelotita extends Component {

  getInnerCircleClasses = (animation) => {
    if (animation === "slow-breathing") {
      return "slow-breathing";
    } else if (animation === "normal-breathing") {
      return "normal-breathing";
    }
    return '';
  }

  getScanLineClasses = (animation) => {
    if (animation === "body-scanning") {
      return "scanning";
    }
    return 'hide';
  }

  render() {
    return <div id="circle">
      <div id="circle-text">{!this.props.playing ? <i id="play-button" className="material-icons" onClick={this.props.onPlay}>play_arrow</i> : <i id="stop-button" className="material-icons" onClick={this.props.onStop}>stop</i>}</div>
      <div id="inner-circle" className={this.getInnerCircleClasses(this.props.animation)}></div>
      <div id="scan-line" className={this.getScanLineClasses(this.props.animation)}></div>
    </div>
  }
}

export default Pelotita;
