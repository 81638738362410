import React, { Component } from 'react';
import { Twemoji } from 'react-emoji-render';
import './App.css';
import * as authentication from './authentication';
import * as meditations from './meditations';
import Pelotita from './Pelotita';
import WakeLock from 'react-wakelock-react16';

const SOUNDS = {
  'bell': new Audio('sounds/bell.mp3'),
}

var deferredPrompt; // Used for PWA homescreen installation

class App extends Component {
  state = {
    skipIntro: false,
    playing: false,
    completed: false,
    showHomescreenBanner: false,
    suggestRegistration: false,
    scriptIndex: 0,
    script: 'ONE_MINUTE',
    userData: authentication.getUserData(),
    statsData: meditations.getStats(),
    showFriendlyMessage: true,
  }

  timeout = null;

  runScript = (index) => {
    const script = meditations.getMeditation(this.state.script).script;
    if (!!script[index]) {
      const line = script[index];
      this.setState({ ...this.state, scriptIndex: index });
      if (!!script[index + 1]) {
        const calculated_text_time = (1 + line.text.split(" ").length) / 2;
        const time = !!line.time ? line.time * 1000 : calculated_text_time * 1000;
        this.timeout = setTimeout(() => { this.runScript(index + 1); }, time);
      } else {
        const newStatsData = meditations.onMeditationCompleted(this.state.script);
        this.setState({ ...this.state, completed: true, suggestRegistration: true, statsData: newStatsData });
      }
      if (!!SOUNDS[line.sound]) {
        SOUNDS[line.sound].pause();
        SOUNDS[line.sound].currentTime = 0;
        SOUNDS[line.sound].play();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scriptIndex !== this.state.scriptIndex) {
      if (this.state.playing) {
        document.getElementById('script-now').classList.remove('fade-in');
        void document.getElementById('script-now').offsetWidth;
        document.getElementById('script-now').classList.add('fade-in');
      }
    }
  }

  play = (e) => {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    this.setState({ ...this.state, playing: true }, () => { this.runScript(this.state.skipIntro ? meditations.getMeditation(this.state.script).introEnd : 0); });
  }

  setScript = (script) => {
    this.setState({ ...this.state, script: script, playing: false });
  }

  stop = () => {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    this.setState({ ...this.state, playing: false });
  }

  addToHomescreen = () => {
    this.setState({ ...this.state, showHomescreenBanner: false });
    deferredPrompt.prompt();  // Wait for the user to respond to the prompt
    deferredPrompt.userChoice
      .then((choiceResult) => {
        /*if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
       }*/
        deferredPrompt = null;
      });
  }

  logout = () => {
    authentication.logout();
  }

  toggleSkipIntro = () => {
    this.setState({ ...this.state, skipIntro: !this.state.skipIntro });
  }

  closeFriendlyMessage = () => {
    this.setState({ ...this.state, showFriendlyMessage: false });
  }

  componentDidMount = () => {
    // Homescreen installation event
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      this.setState({ ...this.state, showHomescreenBanner: true });
    });

    // Attach firebaseui
    authentication.attachUI('#firebaseui-auth-container');

    // Attach to authentication changes
    authentication.onUserDataChanged(userData => {
      this.setState({ ...this.state, userData });
      // Attach to stats changes TODO Handle this not chained
      meditations.onStatsChanged(stats => {
        this.setState({ ...this.state, statsData: stats });
      })
    });

  }

  render() {
    return (
      <div className="App">
        {this.state.showHomescreenBanner ?
          <div id="homescreen-add-banner" onClick={this.addToHomescreen} className="alert alert-success" role="alert">
            <Twemoji text=":point_right: Add to your Homescreen :point_left:" />
          </div>
          : ''}
        <header>
          <div className="menu hide">
            Hi, <strong>anonymous</strong>! [Connect]
          </div>
          <h1>
            <Twemoji text="🧠" />voidhead
      	  </h1>
          <h2>
            <Twemoji text="Take a deep breath 💨" />
          </h2>
        </header>
        <main>
          {this.state.showFriendlyMessage ?
            <div className="alert alert-light" role="alert">
              <Twemoji text="Hi :wave:, this is a work in progress." /> Feedback? Reach me at <a href="https://twitter.com/aaronfc">twitter</a> or by <a href="mailto:yo@aaron.com.es">email</a>.<br />
              <button className="btn btn-link btn-sm top-right" onClick={this.closeFriendlyMessage}>close</button>
            </div>
            : ''}
          <div className="main-wrap">
            <Pelotita animation={meditations.getMeditation(this.state.script).script[this.state.scriptIndex].animation} playing={this.state.playing} onPlay={this.play} onStop={this.stop} />
            <div id="time-selector" className={this.state.playing ? 'hide' : ''}>
              <div>
                <button className={"btn btn-link btn-sm " + (this.state.script === "ONE_MINUTE" ? "bold" : "")} onClick={() => { this.setScript('ONE_MINUTE') }}>1min</button>
                <button className={"btn btn-link btn-sm " + (this.state.script === "FIVE_MINUTES" ? "bold" : "")} onClick={() => { this.setScript('FIVE_MINUTES') }}>5min</button>
                <button className={"btn btn-link btn-sm " + (this.state.script === "TEN_MINUTES" ? "bold" : "")} onClick={() => { this.setScript('TEN_MINUTES') }}>10min</button>
              </div>
              <div>
                <input id="skip-intro" type="checkbox" value={this.state.skipIntro} onChange={this.toggleSkipIntro} /> <label for="skip-intro">Skip intro</label>
              </div>
            </div>
            <div className={this.state.playing ? 'hide' : ''}>
            </div>
            {this.state.playing ?
              <div>
                <div id="script-now" align="center"><Twemoji text={meditations.getMeditation(this.state.script).script[this.state.scriptIndex].text} /></div>
              </div>
              : ''}
            {this.state.completed ?
              <div className="alert alert-success" role="alert">
                <Twemoji text=":tada: Meditation completed!" />
              </div>
              : ''}
            {this.state.suggestRegistration ?
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Making progress!</h5>
                  {this.state.userData.isAnonymous ?
                    <h6 className="card-subtitle mb-2 text-muted">We do not know you yet, but we are proud!</h6>
                    :
                    <h6 className="card-subtitle mb-2 text-muted">Good job, {this.state.userData.name}!</h6>
                  }
                  <p className="card-text">
                    You have meditated for <strong>{(this.state.statsData.timeMeditated / 60).toFixed(2)}</strong> minutes in <strong>{this.state.statsData.sessions}</strong> sessions.
                  </p>
                  {this.state.userData.isAnonymous ?
                    <div>
                      <p className="card-text">
                        Do not lose your progress!
                      </p>
                      <a href="/" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">Sign-up</a> <i><Twemoji text="Psst! Will be quick :P" /></i>
                    </div>
                    : ''}
                </div>
              </div>

              : ''}
            {false && this.state.userData !== null ?
              <div>
                {this.state.userData.isAnonymous === true ?
                  <button className="btn btn-link" data-toggle="modal" data-target="#exampleModalCenter">Login/Sign-up</button>
                  :
                  <button className="btn btn-link" onClick={this.logout}>Logout</button>
                }
              </div>
              : ''}
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalCenterTitle">Login/Sign-up</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div id="firebaseui-auth-container"></div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <WakeLock preventSleep={this.state.playing} />
        </main>
      </div>
    );
  }
}

export default App;
