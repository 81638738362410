import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";

const MEDITATIONS_EN = {
  'TEST': {
    duration: 5,
    introEnd: 0,
    script: [
      { text: '1' },
      { text: '2' },
      { text: '3' },
      { text: 'Caramba :dancer:', sound: 'bell' },
    ],
  },
  'ONE_MINUTE': {
    duration: 60,
    introEnd: 6,
    script: [
      { text: 'Hi 👋 Get ready, this will be quick!' },
      { text: 'Find a comfortable position. Straight back, hands on your legs.' },
      { text: 'You will be focusing on your breath.', animation: 'normal-breathing' },
      { text: 'Distractions are normal. Just come back to your breath.', animation: 'normal-breathing' },
      { text: 'You will hear a 🔔 at the end of the exercise.', animation: 'normal-breathing' },
      { text: 'Like this one 🔔', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'Take ONE deep breath.', animation: 'slow-breathing', time: 10 },
      { text: 'Now gently close your eyes...', animation: 'normal-breathing' },
      { text: 'Keep breathing. Distractions are normal.', animation: 'normal-breathing', time: 60 },
      { text: '🔔 ...', animation: 'normal-breathing', sound: 'bell', time: 4 },
      { text: 'How do you feel?', animation: 'normal-breathing' },
      { text: 'Less agitated?', animation: 'normal-breathing' },
      { text: 'Hope to see you again tomorrow 💪' },
    ],
  },
  'FIVE_MINUTES': {
    duration: 60,
    introEnd: 6,
    script: [
      { text: 'Hi 👋 Get ready, this will be quick!' },
      { text: 'Find a comfortable position. Straight back, hands on your legs.' },
      { text: 'You will be focusing on your breath.', animation: 'normal-breathing' },
      { text: 'Distractions are normal. Just come back to your breath.', animation: 'normal-breathing' },
      { text: 'You will hear a 🔔 at the end of the exercise.', animation: 'normal-breathing' },
      { text: 'Like this one 🔔', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'Take ONE deep breath.', animation: 'slow-breathing', time: 10 },
      { text: 'Now gently close your eyes...', animation: 'normal-breathing' },
      { text: 'Keep breathing. Distractions are normal.', animation: 'normal-breathing', time: 300 },
      { text: '🔔 ...', animation: 'normal-breathing', sound: 'bell', time: 4 },
      { text: 'How do you feel?', animation: 'normal-breathing' },
      { text: 'Less agitated?', animation: 'normal-breathing' },
      { text: 'Hope to see you again tomorrow 💪' },
    ],
  },
  'TEN_MINUTES': {
    duration: 600,
    introEnd: 10,
    script: [
      { text: 'Hi 👋', time: 3 },
      { text: 'Find a comfortable position. Straight back, hands on your legs.' },
      { text: 'Do NOT worry if you get distracted. It is part of the process.', animation: 'normal-breathing' },
      { text: 'If it happens, just go back to your breath.', animation: 'normal-breathing' },
      { text: 'You will hear a 🔔 in the middle of the exercise (5 minutes).', animation: 'normal-breathing' },
      { text: 'Like this one 🔔', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'And you will hear three more at the end 🔔🔔🔔. Like these:', animation: 'normal-breathing' },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: 'Take THREE deep breaths.', animation: 'slow-breathing', time: 30 },
      { text: 'Let your breath go back to it\'s normal rythm.', animation: 'normal-breathing' },
      { text: 'Now, gently close your eyes...', animation: 'normal-breathing' },
      { text: 'Keep breathing. Distractions are normal. ¡Close your eyes!', animation: 'normal-breathing', time: 300 },
      { text: '🔔 (half)', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'Keep breathing. Distractions are normal. ¡Close your eyes!', animation: 'normal-breathing', time: 300 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: 'How do you feel?', animation: 'normal-breathing' },
      { text: 'Less agitated?', animation: 'normal-breathing' },
      { text: 'Hope to see you again tomorrow 💪' },
    ],
  },
};

const MEDITATIONS_ES = {
  'TEST': {
    duration: 5,
    introEnd: 0,
    script: [
      { text: '1' },
      { text: '2' },
      { text: '3' },
      { text: 'Caramba :dancer:', sound: 'bell' },
    ],
  },
  'ONE_MINUTE': {
    duration: 60,
    introEnd: 6,
    script: [
      { text: 'Hola 👋 Prepárate, ¡será rápido!' },
      { text: 'Ponte cómodo. Espalda recta, manos sobre tus piernas.' },
      { text: 'Ahora céntrate en tu respiración.', animation: 'normal-breathing' },
      { text: 'Es normal distraerse. Si ocurre, solo vuelve a la respiración.', animation: 'normal-breathing' },
      { text: 'Oirás una 🔔 al final del ejercicio.', animation: 'normal-breathing' },
      { text: 'Como ésta 🔔', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'Toma UNA respiracion muy profunda.', animation: 'slow-breathing', time: 10 },
      { text: 'Ahora cierra los ojos...', animation: 'normal-breathing' },
      { text: 'Sigue respirando. Las distracciones son normales. ¡Cierra los ojos!', animation: 'normal-breathing', time: 60 },
      { text: '🔔 ...', animation: 'normal-breathing', sound: 'bell', time: 4 },
      { text: '¿Cómo te encuentras?', animation: 'normal-breathing' },
      { text: '¿Menos agitado?', animation: 'normal-breathing' },
      { text: 'Ojalá te veamos de vuelta mañana 💪' },
    ],
  },
  'FIVE_MINUTES': {
    duration: 60,
    introEnd: 6,
    script: [
      { text: 'Hola 👋 Prepárate, ¡será rápido!' },
      { text: 'Ponte cómodo. Espalda recta, manos sobre tus piernas.' },
      { text: 'Ahora céntrate en tu respiración.', animation: 'normal-breathing' },
      { text: 'Es normal distraerse. Si ocurre, solo vuelve a la respiración.', animation: 'normal-breathing' },
      { text: 'Oirás una 🔔 al final del ejercicio.', animation: 'normal-breathing' },
      { text: 'Como ésta 🔔', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'Toma UNA respiracion muy profunda.', animation: 'slow-breathing', time: 10 },
      { text: 'Ahora cierra los ojos...', animation: 'normal-breathing' },
      { text: 'Sigue respirando. Las distracciones son normales. ¡Cierra los ojos!', animation: 'normal-breathing', time: 300 },
      { text: '🔔 ...', animation: 'normal-breathing', sound: 'bell', time: 4 },
      { text: '¿Cómo te encuentras?', animation: 'normal-breathing' },
      { text: '¿Menos agitado?', animation: 'normal-breathing' },
      { text: 'Ojalá te veamos de vuelta mañana 💪' },
    ],
  },
  'TEN_MINUTES': {
    duration: 600,
    introEnd: 10,
    script: [
      { text: 'Hola 👋', time: 3 },
      { text: 'Ponte cómodo. Espalda recta, manos sobre tus piernas.' },
      { text: 'Si te distraes NO pasa nada. Es parte del proceso.', animation: 'normal-breathing' },
      { text: 'Simplemente vuelve a centrarte en tu respiración.', animation: 'normal-breathing' },
      { text: 'Oirás una 🔔 a mitad del ejercicio (5 minutos).', animation: 'normal-breathing' },
      { text: 'Como ésta 🔔', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'Y otras tres al final 🔔🔔🔔. Como éstas:', animation: 'normal-breathing' },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: 'Respira profundamente TRES veces.', animation: 'slow-breathing', time: 30 },
      { text: 'Deja que tu respiración vuelva a tu ritmo normal.', animation: 'normal-breathing' },
      { text: 'Ahora cierra los ojos...', animation: 'normal-breathing' },
      { text: 'Sigue respirando. Las distracciones son normales. ¡Cierra los ojos!', animation: 'normal-breathing', time: 300 },
      { text: '🔔 (estás a mitad)', animation: 'normal-breathing', sound: 'bell', time: 3 },
      { text: 'Sigue respirando. Las distracciones son normales. ¡Cierra los ojos!', animation: 'normal-breathing', time: 300 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '🔔', animation: 'normal-breathing', sound: 'bell', time: 1.5 },
      { text: '¿Cómo te encuentras?', animation: 'normal-breathing' },
      { text: '¿Menos agitado?', animation: 'normal-breathing' },
      { text: 'Ojalá te veamos de vuelta mañana 💪' },
    ],
  },
};

export const getMeditation = (meditationId) => {
  const lang = navigator.language || navigator.userLanguage;
  if (lang === "es-ES") {
    return MEDITATIONS_ES[meditationId];
  }
  return MEDITATIONS_EN[meditationId];
}

export const getStats = () => {
  const stats = JSON.parse(localStorage.getItem('stats_data')) || { sessions: 0, timeMeditated: 0 };
  const unsynced = JSON.parse(localStorage.getItem('stats_data_unsynced')) || { sessions: 0, timeMeditated: 0 }
  return { sessions: stats.sessions + unsynced.sessions, timeMeditated: stats.timeMeditated + unsynced.timeMeditated };
}

export const onStatsChanged = (callback) => {
  const user = firebase.auth().currentUser;
  if (!!user && !user.isAnonymous) {
    firebase.database().ref('/tmp_stats/' + user.uid).on('value', (snapshot) => {
      if (!snapshot.exists()) {
        // Create stats
        firebase.database().ref('/tmp_stats/' + user.uid).set(getStats(), (e) => console.log(e));
      } else {
        localStorage.setItem('stats_data', JSON.stringify(snapshot.val()));
        callback(snapshot.val());
      }
    }, e => console.log(e));
  }
}

export const onMeditationCompleted = (meditationId) => {
  // If connected:
  // Update localStorage stats
  const newStatsData = { ...getStats() };
  newStatsData.sessions += 1;
  newStatsData.timeMeditated += getMeditation(meditationId).duration;
  localStorage.setItem('stats_data', JSON.stringify(newStatsData));
  // Update on firebase
  const user = firebase.auth().currentUser;
  if (!!user && !user.isAnonymous) {
    firebase.database().ref('/tmp_stats/' + user.uid).set(newStatsData, (e) => console.log(e));
  }
  // else:
  // Update localstorage stats_unsynced

  return newStatsData;
}
