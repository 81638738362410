import firebase from 'firebase/app';
import firebaseui from 'firebaseui';
import "firebase/auth";
import "firebase/database";
// Config
import firebaseUiConfig from './config/firebase-ui';
import firebaseConfig from './config/firebase';

export const init = () => {
  firebase.initializeApp(firebaseConfig);
}

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('user_data')) || null;
}

export const attachUI = (elementId) => {
  // Initialize the FirebaseUI Widget using Firebase.
  var ui = new firebaseui.auth.AuthUI(firebase.auth());
  // The start method will wait until the DOM is loaded.
  ui.start(elementId, firebaseUiConfig);
}

export const onUserDataChanged = (callback) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in.
      var displayName = user.displayName;
      var email = user.email;
      var uid = user.uid;
      var isAnonymous = user.isAnonymous;
      //var emailVerified = user.emailVerified;
      //var photoURL = user.photoURL;
      //var phoneNumber = user.phoneNumber;
      //var providerData = user.providerData;
      user.getIdToken().then((accessToken) => {
        //console.log(user);
        const userData = {
          uid: uid,
          name: displayName || 'anonymous',
          isAnonymous: isAnonymous,
          email: email,
        }
        if (!isAnonymous) {
          firebase.database().ref('/users/' + uid).on('value', (snapshot) => {
            if (!snapshot.exists()) {
              // Create user
              firebase.database().ref('/users/' + uid).set(userData, (e) => console.log(e));
            } else {
              localStorage.setItem('user_data', JSON.stringify(snapshot.val()));
              callback(snapshot.val());
            }
          });
        }
        localStorage.setItem('user_data', JSON.stringify(userData));
        callback(userData);
      });
    } else {
      // Logged out
      firebase.auth().signInAnonymously();
    }
  }, (e) => console.log(e));
}

export const logout = () => {
  firebase.auth().signOut();
  localStorage.clear();
}
