import firebase from 'firebase/app';
import "firebase/auth";

export default {
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID, // TODO <--- Enable
  ],
  tosUrl: 'https://voidhead.com/tos.html', // FIXME <---
  privacyPolicyUrl: function() {
    window.location.assign('https://voidhead.com/privacy.html'); // FIXME <---
  }
};
